<template>
<div class="card">
  <div class="card-body">
    <div class="row">
        <div v-if="specialText" class="col-md-7">
            <span class="text-danger cursor" href="#!" @click="showInfo">{{ specialText.heading }}</span>
            <button class="btn btn-primary btn-sm" @click="showInfo">
                <i class="fas fa-info-circle text-white" v-if="btnStatus"></i>
                <i class="fas fa-times text-white" v-if="!btnStatus"></i>
            </button>
        </div>
        <div class="col-md-5 text-end">
            <strong>Kostenlose Beratung: 030 31007700</strong>
        </div>
        <div class="col-md-12">
            <p v-if="statusInfo && specialText" class="text-primary">
                {{ specialText.body }}
            </p>
            <ul class="list-inline mb-0" v-if="!statusInfo">
                <li v-for="(item, index) in items" :key="index" class="list-inline-item">
                    <i class="fas fa-check text-success" aria-hidden="true"></i> {{ item }}
                </li>
            </ul>
        </div>
    </div>
  </div>
</div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const items = ref(null)
    const btnStatus = ref(true)
    const statusInfo = ref(false)
    const discount = 30
    onMounted(() => {
      updateInfoText()
      setInterval(() => {
        updateInfoText()
      }, 5000)
    })

    // computed
    const specialText = computed(() => {
      return store.getters['configurator/specialText']
    })

    // methods
    function updateInfoText () {
      const s = store.getters['configurator/usps'].sort(() => {
        return 0.5 - Math.random()
      })
      items.value = s.slice(0, 3)
    }
    function showInfo () {
      btnStatus.value = !btnStatus.value
      statusInfo.value = !statusInfo.value
    }

    return {
      specialText,
      items,
      btnStatus,
      statusInfo,
      showInfo,
      discount
    }
  }
}
</script>
<style lang="scss" scoped>
    .list-inline-item {
        font-size: 0.81rem;
    }

    .card-block {
        padding: 0.81rem;
        width: 100%;
    }
    .cursor {
        cursor: pointer
    }
</style>
